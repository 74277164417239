import React, { cloneElement } from "react";
import { MDBAnimation, MDBModal, MDBModalBody } from "mdbreact";
import Lightbox from "react-image-lightbox";
import moment from "moment";
const images = [
  "images/portfolio/page1.jpg",
  "images/portfolio/page2.jpg",
  "images/portfolio/page3.jpg",
];
const imgProductInfo = ["images/assets/ProductInfo.jpg"];
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenProductInfo: false,
      isOpenGetToKnow: false,
      isOpenHandle: false,
      isOpenClip: false,
      timeText: "",
      photoIndex: 0,
      photoIndexProduct: 0,
      isOpenPortfolio: false,
      isFullScreen: false,
    };
  }
  componentDidMount() {
    let timeNow = new Date();
    let timeNowH = moment(timeNow).format("HH");
    let timeNowHInt = parseInt(timeNowH);
    //let timeNowHInt = 16;
    if (timeNowHInt < 12 && timeNowHInt > 6) {
      this.setState({ timeText: "morning" });
      //console.log("morning");
    } else if (timeNowHInt < 18 && timeNowHInt > 12) {
      this.setState({ timeText: "afternoon" });
      //console.log("afternoon");
    } else {
      this.setState({ timeText: "night" });
      //console.log("night");
    }
  }
  componentWillUnmount() {}

  toggleGetToKnow = () => {
    this.setState({ isOpenGetToKnow: !this.state.isOpenGetToKnow });
  };
  togglePortfolio = () => {
    this.setState({ isOpenPortfolio: !this.state.isOpenPortfolio });
  };
  toggleProductInfo = () => {
    this.setState({ isOpenProductInfo: !this.state.isOpenProductInfo });
  };
  toggleHandle = () => {
    this.setState({ isOpenHandle: !this.state.isOpenHandle });
  };
  toggleClip = () => {
    this.setState({ isOpenClip: !this.state.isOpenClip });
  };
  toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullscreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  };
  render() {
    return (
      <>

        <div className="mainContainVirtual">
          <div className="screenStage">
          <MDBAnimation  type="fadeIn" delay="1.5s">
              <img
                src="images/backgrounds/white-bg-text.jpg"
                alt="stage morning"
                className="imgScreenStage"
              />

          </MDBAnimation>
           
            <MDBAnimation className="spiderman" type="fadeInLeft" delay="1.5s">
              <img
                src="images/assets/spiderman.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>
            
            <MDBAnimation
              className="mickey"
              type="fadeInLeft"
              delay="1.5s"
            >
              <img
                src="images/assets/mickey.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>



            <MDBAnimation
              className="starwar"
              type="fadeInRight"
              delay="1.5s"
            >
              <img
                src="images/assets/starwar.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>


            <MDBAnimation
              className="BuzzLightyear"
              type="fadeInRight"
              delay="1.5s"
            >
              <img
                src="images/assets/BuzzLightyear.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>


            <MDBAnimation
              className="wolf"
              type="fadeInDown"
              delay="1.5s"
            >
              <img
                src="images/assets/wolf.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>

            <MDBAnimation
              className="avatar"
              type="fadeInUp"
              delay="1.5s"
            >
              <img
                src="images/assets/avatar.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>

           

          
          </div>

          
        </div>
        <MDBAnimation  type="fadeIn" delay="1.5s">
        <div className="text-link">
          <center>
          <a href="th/">ภาษาไทย </a> / <a href="en/">English</a>
          </center>
          </div>
        </MDBAnimation>
        
      </>
    );
  }
}

export default App;
